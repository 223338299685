import React from "react";
import noImage from "../../../assets/images/no_image.jpg";
import tag from "../../../assets/images/tag.png";
import _ from "underscore";
import "./Summary.css";
import { CURRENCY, getFileThumb } from "../../../Api";
import CurrencyFormat from 'react-currency-format';
import { useMediaQuery } from "@uidotdev/usehooks";

const Summary = ({ btnColor, btnTextColor, cart, address, validEmail, showGift, removeDiscount, type, applyDiscountCode, handleDiscountInput, enterCode }) => {
    console.log("Summary cart: ", cart)
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    return (
        <div className="checkout_cart_summary">
            <div className="checkout_cart_list">
                {cart && !_.isEmpty(cart) && cart.cart_items && cart.cart_items.length > 0 ?
                    <>
                        {cart.cart_items.map((data, index) => {
                            let img = ""
                            let title = ""
                            let price = "0"
                            let variant = ""
                            if (type == "cart") {
                                img = data?.product_variant?.Image?.src || (data?.product_variant?.Product?.images && data?.product_variant?.Product.images.length > 0 && _.sortBy(data?.product_variant?.Product.images, "position")[0]?.src)
                                title = data?.product_variant?.Product?.title
                                price = data?.product_variant?.price
                                variant = data?.product_variant?.title
                            } else {
                                img = data?.product_variant?.Image?.src || (data?.product_variant?.Product?.images && data?.product_variant?.Product.images.length > 0 && _.sortBy(data?.product_variant?.Product.images, "position")[0]?.src)
                                title = data?.title
                                price = data?.price
                                variant = data?.variant_title
                            }
                            console.log("variant: ", data)
                            console.log("variant img: ", img)
                            return (
                                <div className="checkout_cart_item" key={index}>
                                    <span className="cart_img">
                                        {/* <img src={img || noImage} alt="Image" className="img-fluid" /> */}
                                        {getFileThumb(img || noImage)}
                                    </span>
                                    <div className="checkout_cart_info">
                                        <div>
                                            <h6 className="mb-0">{title || ""}</h6>
                                            {variant !== "" && variant !== "Default Title" ?
                                                <p><small>{variant || ""}</small></p>
                                                : null
                                            }
                                        </div>
                                        <div className="checkout_cart_price text-end">
                                            {data?.quantity} X <strong>
                                                <CurrencyFormat value={parseFloat(price || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={CURRENCY} />
                                            </strong>
                                            {data?.price_after_discount ?
                                                <p>
                                                    {data?.discount_per_item ?
                                                        <>
                                                            <span style={{ color: "red" }}>
                                                                -<CurrencyFormat value={parseFloat(data?.discount_per_item).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={CURRENCY} />
                                                            </span><br />
                                                        </>
                                                        : null
                                                    }
                                                    Total: <CurrencyFormat value={parseFloat(data?.price_after_discount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={CURRENCY} />
                                                </p>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                    :
                    <h6>Your cart is empty</h6>
                }
            </div>
            {cart && !_.isEmpty(cart) &&
                <>
                    <div className="checkout_gift_card">
                        {type == "cart" && showGift &&
                            <form className="checkout_row flex-nowrap checkout_discount align-items-end" onSubmit={enterCode !== "" && applyDiscountCode}>
                                <div className="checkout_col">
                                    <div className="form_control mb-0">
                                        {/* <label className="form_label">Enter discount code</label> */}
                                        <input type="text" className="checkout_input" name="enterCode" placeholder="Enter discount/gift card code" value={enterCode} onChange={handleDiscountInput} />
                                    </div>
                                </div>
                                <div className="col">
                                    <button className={`btn custom_btn ${enterCode == "" ? "disabled" : ""}`} style={{ backgroundColor: prefersDarkMode ? "#fff" : btnColor, color: prefersDarkMode ? "#000" : btnTextColor }} >Apply</button>
                                </div>
                            </form>
                        }
                        {
                            cart?.discount_code || cart?.applied_discount?.amount ?
                                <div className="added_discount">
                                    <strong>(<img src={tag} width={15} alt="" /> {cart?.discount_code?.title || cart?.applied_discount?.title})</strong>
                                    {type == "cart" &&
                                        <button className="remove_discount" onClick={() => removeDiscount(cart?.discount_code?.title || cart?.applied_discount?.title)}>Remove</button>
                                    }
                                </div>
                                :
                                cart && cart.cart_items.filter((x) => x?.discount_code_id && x?.discount_code).length > 0 ?
                                    <div className="added_discount">
                                        <strong><img src={tag} width={15} alt="" /> {cart.cart_items.filter((x) => x?.discount_code_id && x?.discount_code)[0]?.discount_code?.title}</strong>
                                        {type == "cart" &&
                                            <button className="remove_discount" onClick={() => removeDiscount(cart.cart_items.filter((x) => x?.discount_code_id && x?.discount_code)[0]?.discount_code?.title)}>Remove</button>
                                        }
                                    </div>
                                    :
                                    cart && cart.gift_card_id ?
                                        <div className="added_discount">
                                            <strong><img src={tag} width={15} alt="" /> {cart?.gift_card?.code || "Gift card"}</strong>
                                            {type == "cart" &&
                                                <button className="remove_discount" onClick={() => removeDiscount(cart?.gift_card?.code)}>Remove</button>
                                            }
                                        </div>
                                        :
                                        null
                        }
                        {/* {type == "cart" &&
                    <button className="link" onClick={showDiscountBar}>Choose discount code</button>
                } */}
                    </div>
                    {cart && !_.isEmpty(cart) &&
                        <div className="checkout_cart_total">
                            <hr />
                            <ul>
                                <li>
                                    <label>Subtotal: </label>
                                    <span><CurrencyFormat value={parseFloat(cart?.sub_total || cart?.subtotal_price || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={CURRENCY} /></span>
                                </li>
                                {/* {(cart?.discount && cart?.discount !== "" && cart?.discount !== "0.00" && cart?.discount !== "0" && cart?.discount !== 0)
                                    || (cart?.applied_discount?.amount && cart?.applied_discount?.amount !== "" && cart?.applied_discount?.amount !== "0.00" && cart?.applied_discount?.amount !== "0" && cart?.applied_discount?.amount !== 0) ? */}
                                <li>
                                    <label>Discount:
                                    </label>
                                    <span style={{ color: "red" }}>-<CurrencyFormat value={parseFloat(cart?.discount || cart?.applied_discount?.amount || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={CURRENCY} /></span>
                                </li>
                                {/* : null
                                } */}
                                <li>
                                    <label>Shipping: </label>
                                    <span><CurrencyFormat value={parseFloat(cart?.delivery_fee || cart?.shipping_line?.price || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={CURRENCY} /></span>
                                </li>
                                <li>
                                    {type == "cart" ?
                                        <>
                                            <label>Tax: {" "}
                                                {!_.isEmpty(cart?.taxObj) && parseFloat(cart?.tax || cart?.total_tax) ?
                                                    <small>({cart?.taxObj?.tax_type || ""} {cart?.taxObj?.tax_rate || 0}%)</small>
                                                    : null
                                                }
                                            </label>
                                            <span><CurrencyFormat value={parseFloat(cart?.tax || cart?.total_tax || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={CURRENCY} /></span>
                                        </>
                                        :
                                        <>
                                            <label>Tax: {" "}
                                                {!_.isEmpty(cart?.taxObj) && cart?.taxes_included && parseFloat(cart?.tax || cart?.total_tax) ?
                                                    <small>({cart?.taxObj?.tax_type || ""} {cart?.taxObj?.tax_rate || 0}%)</small>
                                                    : null
                                                }
                                            </label>
                                            <span><CurrencyFormat value={parseFloat(cart?.taxes_included ? cart?.tax || cart?.total_tax || 0 : 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={CURRENCY} /></span>
                                        </>
                                    }
                                </li>
                                {/* <li>
                            <label>Taxes: </label>
                            <span><CurrencyFormat value={parseFloat(cart?.tax || cart?.total_tax || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={CURRENCY} /></span>
                        </li> */}
                                <li>
                                    <label><strong>Total:</strong> </label>
                                    <strong><CurrencyFormat value={parseFloat(cart?.total || cart?.total_price || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={CURRENCY} /></strong>
                                </li>
                            </ul>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export { Summary }